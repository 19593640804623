import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import { ProjectProvider } from "../contexts/ProjectContext";
import HomePage from "../pages/HomePage/HomePage";
import SignInPage from "../pages/SignInPage/SignInPage";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import AccountPage from "../pages/AccountPage/AccountPage";
import Navbar from "../components/Navbar/Navbar";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import VerifyEmailPage from "../pages/VerifyEmail/VerifyEmail";
import AboutUsPage from "../pages/AboutUs/AboutUs";
import InstallAppPage from "../pages/InstallAppPage/InstallAppPage";
import ProjectAddressPage from "../pages/projectCreation/ProjectAddressPage/ProjectAddressPage";
import ConfirmAddressPage from "../pages/projectCreation/ConfirmAddressPage/ConfirmAddressPage";
import ProjectDetailsPage from "../pages/projectCreation/ProjectDetailsPage/ProjectDetailsPage";
import ProjectHomePage from "../pages/ProjectHome/ProjectHome";
import CreateEstimatePage from "../pages/projectCreation/CreateEstimatePage/CreateEstimatePage";
import BlueprintsPage from "../pages/projectCreation/BlueprintsPage/BlueprintsPage";
import PhotosPage from "../pages/projectCreation/PhotosPage/PhotosPage";
import FinishPage from "../pages/projectCreation/FinishPage/FinishPage";
import ViewBlueprintPage from "../components/BlueprintPage/ViewBlueprint/ViewBlueprintPage";
import RequireEmailVerification from "../utils/RequireEmailVerification";
import LeaderboardPage from "../pages/Leaderboard/LeaderboardPage";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import "./App.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <ErrorBoundary>
          <ProjectProvider>
            <div className="flex flex-col h-full min-h-screen">
              <Navbar />
              <main className="flex-grow h-full md:p-12">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <HomePage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/signin"
                    element={
                      <PublicRoute>
                        <SignInPage />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <PublicRoute>
                        <RegisterPage />
                      </PublicRoute>
                    }
                  />
                  <Route path="/about-us" element={<AboutUsPage />} />
                  <Route
                    path="/account"
                    element={
                      <PrivateRoute>
                        <AccountPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/project-address"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <ProjectAddressPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/confirm-address"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <ConfirmAddressPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/project-details"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <ProjectDetailsPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/project-home/:projectId"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <ProjectHomePage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/create-estimate/:projectId"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <CreateEstimatePage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/blueprints/:projectId"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <BlueprintsPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/photos/:projectId"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <PhotosPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/finish/:projectId"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <FinishPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/leaderboard"
                    element={
                      <PrivateRoute>
                        <LeaderboardPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/view-blueprint/:projectId/:blueprintName"
                    element={
                      <PrivateRoute>
                        <RequireEmailVerification>
                          <ViewBlueprintPage />
                        </RequireEmailVerification>
                      </PrivateRoute>
                    }
                  />
                  <Route path="/install-app" element={<InstallAppPage />} />
                  <Route path="/verify-email" element={<VerifyEmailPage />} />
                </Routes>
              </main>
            </div>
          </ProjectProvider>
        </ErrorBoundary>
      </AuthProvider>
    </Router>
  );
}

export default App;
