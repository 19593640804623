import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useProjects } from "../../contexts/ProjectContext";
import { db } from "../../services/firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import ProjectDropdown from "../../components/HomePage/ProjectDropdown/ProjectDropdown";
import ProjectLimitDialog from "../../components/ProjectLimitDialog/ProjectLimitDialog";
import { Project } from "../../types/Project";
import { AiOutlinePlus } from "react-icons/ai";

const MAX_PROJECTS = 5; // Maximum number of projects allowed

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  const { setActiveJobs, activeJobs, setCurrentProject } = useProjects();
  const companyName = userData?.companyName || "Your Company Name";
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Define loading state

  const fetchProjects = async () => {
    if (!currentUser) return;

    try {
      const q = query(
        collection(db, "projects"),
        where("userId", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const projects: Project[] = [];

      querySnapshot.forEach((doc) => {
        const project = { id: doc.id, ...doc.data() } as Project;
        projects.push(project);
      });

      setActiveJobs(projects);
      setLoading(false); // Set loading to false when fetch is done
    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false); // Set loading to false even on error
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentUser]);

  const handleProjectClick = async (projectId: string) => {
    try {
      const projectDoc = await getDoc(doc(db, "projects", projectId));
      if (projectDoc.exists()) {
        const projectData = {
          id: projectDoc.id,
          ...projectDoc.data(),
        } as Project;
        setCurrentProject(projectData);
        navigate(`/project-home/${projectId}`);
      } else {
        console.error("No project found with the given ID");
      }
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  const handleNewProjectClick = () => {
    const commercialProjects = activeJobs.filter(
      (project) => project.workType === "commercial"
    );
    const residentialProjects = activeJobs.filter(
      (project) => project.workType === "residential"
    );
    const totalProjects =
      commercialProjects.length + residentialProjects.length;

    if (totalProjects >= MAX_PROJECTS) {
      setDialogOpen(true); // Show limit dialog if user has reached project limit
    } else {
      navigate("/project-address"); // Allow project creation if under limit
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const commercialProjects = activeJobs.filter(
    (project) => project.workType === "commercial"
  );
  const residentialProjects = activeJobs.filter(
    (project) => project.workType === "residential"
  );

  if (loading) {
    return <div>Loading projects...</div>; // Show loading spinner or text when fetching projects
  }

  return (
    <Page className="mx-4">
      <div className="mt-4 flex items-center justify-between w-full text-base">
        <h1 className="text-xl md:text-3xl font-semibold">{companyName}</h1>
        <CustomButton
          icon={<AiOutlinePlus />}
          variant="contained"
          color="primary"
          className=""
          iconPosition="right"
          fontSize="0.6rem"
          onClick={handleNewProjectClick}
        >
          new project
        </CustomButton>
      </div>

      <h2 className="text-lg mt-[3rem] font-semibold">Project Feed: </h2>
      <div className="flex flex-col md:flex-row md:justify-between md:space-x-4 ">
        <div className=" md:w-1/2">
          <ProjectDropdown
            title="commercial projects"
            projects={commercialProjects}
            onProjectClick={handleProjectClick}
            progressPercentage={66}
          />
        </div>

        <div className="md:w-1/2">
          <ProjectDropdown
            title="residential projects"
            projects={residentialProjects}
            onProjectClick={handleProjectClick}
            progressPercentage={34}
          />
        </div>
      </div>

      {/* Beta Testing Notice */}
      <div className="fixed bottom-0  px-4 pb-4 left-0 right-0 bg-yellow-500 text-center py-1">
        <p className="text-white font-semibold text-lg">
          Trade Forge is currently in production. You can create up to 5
          projects.
        </p>
      </div>

      <ProjectLimitDialog open={dialogOpen} onClose={handleDialogClose} />
    </Page>
  );
};

export default HomePage;
