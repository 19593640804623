import React from "react";
import { AiOutlineClose, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { NavigateFunction } from "react-router-dom";

interface ViewerHeaderProps {
  navigate: NavigateFunction;
  blueprintName: string;
  currentPage: number;
  numPages: number;
  onPrevPage: () => void;
  onNextPage: () => void;
}

const ViewerHeader: React.FC<ViewerHeaderProps> = ({ navigate, blueprintName, currentPage, numPages, onPrevPage, onNextPage }) => (
  <div className="sticky top-0 left-0 right-0 z-10 bg-gray-200 p-4 flex justify-between items-center">
    <AiOutlineClose onClick={() => navigate(-1 as unknown as string)} className="text-2xl cursor-pointer" />
    <div className="flex items-center space-x-2">
      <AiOutlineArrowLeft 
        onClick={onPrevPage} 
        className={`text-2xl cursor-pointer ${currentPage === 1 ? 'text-gray-400' : 'text-black'}`} 
      />
      <span>{currentPage} of {numPages}</span>
      <AiOutlineArrowRight 
        onClick={onNextPage} 
        className={`text-2xl cursor-pointer ${currentPage === numPages ? 'text-gray-400' : 'text-black'}`} 
      />
    </div>
  </div>
);

export default ViewerHeader;
