import React from 'react';

interface PillSelectorProps {
  selectedPill: string;
  onPillClick: (pill: string) => void;
}

const PillSelector: React.FC<PillSelectorProps> = ({ selectedPill, onPillClick }) => {
  const pills = ["Estimates Created", "Estimates Sent", "Estimate Accuracy", "Profit Per Hour Bidding"];

  return (
    <div className="flex overflow-x-auto mt-4 space-x-0.5 text-base rounded-lg">
      {pills.map((pill) => (
        <button
          key={pill}
          onClick={() => onPillClick(pill)}
          className={`px-4 py-1 min-w-[170px] rounded-full text-sm ${selectedPill === pill ? "bg-blue-500 text-white" : "bg-white text-gray-700"}`}
        >
          {pill}
        </button>
      ))}
    </div>
  );
};

export default PillSelector;
