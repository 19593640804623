import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiMenu, HiX } from "react-icons/hi";
import { FaUserCircle } from "react-icons/fa";
import { MdGetApp } from "react-icons/md";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { AuthContext } from "../../contexts/AuthContext";
import CustomButton from "../CustomButton";
import { getDeviceType, isStandalone } from "../../utils/getDeviceType";
import NavLinks from "./NavLinks";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const currentUser = authContext ? authContext.currentUser : null;
  const deviceType = getDeviceType();
  const standalone = isStandalone();
  const location = useLocation();
  const toggleNav = () => setIsOpen(!isOpen);

  // Define paths where the navbar should be hidden using regular expressions
  const hideNavbarPaths = [
    /^\/project-address$/,
    /^\/confirm-address$/,
    /^\/project-details$/,
    /^\/create-estimate\/.*$/,
    /^\/project-estimate\/.*$/,
    /^\/blueprints\/.*$/,
    /^\/photos\/.*$/,
    /^\/finish\/.*$/,
  ];

  // Check if the current path matches any of the paths to hide the navbar
  const isHiddenPath = hideNavbarPaths.some((path) => path.test(location.pathname));
  const isProjectHomePath = /^\/project-home\/.+$/.test(location.pathname);

  // Determine whether to show the navbar
  const showNavbar = !isHiddenPath && !isProjectHomePath;

  // Determine whether to show the "Get the App" button
  const showGetAppButton =
    deviceType !== "desktop" &&
    location.pathname !== "/install-app" &&
    !currentUser &&
    !standalone;

  if (!showNavbar) {
    return null; // No navbar at certain paths
  }

  return (
    <header className="bg-gray-700 text-white w-full p-4 fixed top-0 left-0 shadow-md z-10">
      <div className="container mx-auto flex justify-between items-center">
        <button onClick={toggleNav} className="text-white focus:outline-none lg:hidden">
          {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
        </button>
        <div className="hidden lg:flex w-full justify-between items-center">
          <nav className="flex space-x-4">
            <NavLinks currentUser={currentUser} showGetAppButton={showGetAppButton} />
          </nav>
          {currentUser && (
            <Link to="/account">
              <FaUserCircle size={30} className="cursor-pointer" />
            </Link>
          )}
        </div>
        {showGetAppButton && (
          <CustomButton
            icon={<MdGetApp />}
            iconPosition="left"
            variant="contained"
            color="info"
            onClick={() => (window.location.href = "/install-app")}
            className="text-white"
            fontSize="0.7rem"
          >
            Get the App
          </CustomButton>
        )}
        {currentUser && (
          <Link to="/account" className="lg:hidden">
            <AccountCircle fontSize="large" />
          </Link>
        )}
      </div>
      <nav
        className={`${isOpen ? "flex" : "hidden"} lg:hidden fixed inset-0 bg-gray-700 bg-opacity-95 flex-col items-center justify-center`}
      >
        <ul>
          <NavLinks currentUser={currentUser} showGetAppButton={showGetAppButton} onClick={toggleNav} />
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
