import { Worker, Viewer, SpecialZoomLevel, ScrollMode, ViewMode } from '@react-pdf-viewer/core';
import * as pdfjs from 'pdfjs-dist';
import FloatingMenu from './FloatingMenu';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface BlueprintViewerProps {
  projectId: string;
  blueprintName: string;
  currentPage: number;
  onLoadSuccess: (numPages: number) => void;
}

const BlueprintViewer: React.FC<BlueprintViewerProps> = ({ projectId, blueprintName, currentPage, onLoadSuccess }) => (
  <div className="blueprint-viewer-container relative">
    <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
      <div className="blueprint-viewer">
        <Viewer
          fileUrl={`https://firebasestorage.googleapis.com/v0/b/trade-forge.appspot.com/o/projects%2F${projectId}%2F${encodeURIComponent(
            blueprintName
          )}?alt=media`}
          defaultScale={SpecialZoomLevel.PageFit}
          initialPage={currentPage - 1}
          onDocumentLoad={({ doc }) => onLoadSuccess(doc.numPages)}
          scrollMode={ScrollMode.Page} // Ensure scrolling is per page
          viewMode={ViewMode.SinglePage} // Ensure only one page is shown
        />
      </div>
    </Worker>
    <FloatingMenu />
  </div>
);

export default BlueprintViewer;
