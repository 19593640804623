import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewerHeader from "./ViewerHeader";
import BlueprintViewer from "./BlueprintViewer";
import './view-blueprint-page.css';  

const ViewBlueprintPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, blueprintName } = useParams<{ projectId: string; blueprintName: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  if (!projectId || !blueprintName) {
    return <div>Error: Missing project ID or blueprint name</div>;
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="blueprint-page-container">
      <ViewerHeader 
        navigate={navigate} 
        blueprintName={blueprintName} 
        currentPage={currentPage} 
        numPages={numPages} 
        onPrevPage={handlePrevPage} 
        onNextPage={handleNextPage} 
      />
      <BlueprintViewer 
        projectId={projectId} 
        blueprintName={blueprintName} 
        currentPage={currentPage}
        onLoadSuccess={setNumPages} 
      />
    </div>
  );
};

export default ViewBlueprintPage;
